import React from 'react';
import styled from 'styled-components';

const StyledPageFooter = styled.footer`
  background-color: var(--bg-page-footer);
  height: 18rem;
  margin-bottom: 5rem;
  padding-top: 1rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  @media (min-width: 576px) {
    padding-top: 2.5rem;
    height: 10rem;
  }
  .page-footer-desktop-container {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'NexaLight';
    @media (min-width: 576px) {
      width: 1100px;
      flex-direction: row;
      justify-content: space-between;
    }
    .regular {
      font-family: 'NexaRegular';
    }
    .logo-container {
      svg {
        width: 4rem;
        height: 4rem;
        @media (min-width: 576px) {
          width: 5rem;
          height: 5rem;
        }
      }
      display: flex;
      align-items: center;
      p {
        color: var(--rojo-nuevo-principal);
        margin-left: 0.5rem;
      }
    }
    .frecuencias,
    .direccion {
      color: #3d3d3f;
      font-size: 0.85rem;
      @media (min-width: 576px) {
        font-size: 1rem;
      }
    }
    .direccion a {
      text-decoration: underline;
    }
    .frecuencias {
      display: flex;
      margin-top: 0.5rem;
      padding-left: 1.5rem;
      padding-right: 0.5rem;
      height: 2rem;
      align-items: center;
      p {
        margin-right: 1rem;
        b {
          font-family: 'NexaBold';
        }
      }
      @media (min-width: 576px) {
        margin-top: 1.5rem;
        border-right: 0.5px solid #3d3d3f;
        border-left: 0.5px solid #3d3d3f;
      }
    }
    .direccion {
      margin-top: 0.5rem;
      text-align: center;
      @media (min-width: 576px) {
        text-align: left;
      }
    }
  }
`;

const PageFooter = () => (
  <StyledPageFooter>
    <div className="page-footer-desktop-container">
      <div className="logo-container">
        <svg
          fill="none"
          height="80"
          viewBox="0 0 80 80"
          width="80"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.0899 36.5436L24.8379 43.5006H24.2442L18.9466 36.6322L19.0836 48.1977L15.9781 48.2421L15.7954 32.334L19.3576 32.2896L24.5639 39.0694L29.6332 32.201L33.1497 32.1567L33.3324 48.0648L30.2269 48.1091L30.0899 36.5436Z"
            fill="#EB2227"
          />
          <path
            d="M35.8899 36.7209C35.8899 33.3531 39.1324 31.8908 42.1465 31.8465C45.4347 31.8022 48.9969 33.0873 49.0426 36.8538C49.0883 40.8862 45.9371 41.6838 42.5119 41.7282C41.1418 41.7282 38.9497 42.127 38.9954 43.8108V45.1845L49.1796 45.0959L49.2253 47.8876L35.9355 48.0205C35.9355 46.6468 35.8899 45.2288 35.8899 43.8552C35.8442 40.1772 39.2694 39.0694 42.4662 39.0251C43.6993 39.0251 45.9828 38.8036 45.9371 36.9424C45.9371 35.2143 44.293 34.4166 42.1922 34.4166C40.5938 34.4166 38.904 35.2586 38.904 36.6766L35.8899 36.7209Z"
            fill="#EB2227"
          />
          <path
            d="M62.104 31.8465L62.1954 41.8168H64.1135L64.1591 44.7414H62.241L62.2867 47.7546L59.2269 47.7989L59.1812 44.7857L50.4127 44.8743L50.093 41.8168L57.3544 31.9352L62.104 31.8465ZM59.1812 41.8611L59.0899 34.5053L53.6096 41.9054L59.1812 41.8611Z"
            fill="#EB2227"
          />
          <path
            d="M10.0867 77.9313L0.907227 10.9755L69.9134 2.0687L79.093 69.0245L10.0867 77.9313ZM5.61117 14.4761L13.6946 73.3228L74.3434 65.4795L66.2599 6.63286L5.61117 14.4761Z"
            fill="#EB2227"
          />
        </svg>
        <p>La radio que nos mueve</p>
      </div>
      <div className="frecuencias">
        <p>
          <b>97.9 fm</b> MVD
        </p>
        <p>
          <b>102.5 fm</b> Maldonado
        </p>
      </div>
      <div className="direccion">
        <p className="regular">Misiones 1475 piso 6</p>
        <p>Montevideo, Uruguay</p>
        <p className="regular">Línea directa administración: 2915 1779</p>
        <p className="regular">
          Línea directa comercial:{' '}
          <a href="mailto:andrea@m24.com.uy" rel="noopener noreferrer" target="_blank">
            andrea@m24.com.uy
          </a>
        </p>
      </div>
    </div>
  </StyledPageFooter>
);

export default PageFooter;
