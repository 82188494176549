import React, { useState } from 'react';
import styled from 'styled-components';

import playIcon from '../../images/play-blanco.svg';
import pauseIcon from '../../images/pausa.svg';
import menos1Icon from '../../images/menos1.svg';
import menos5Icon from '../../images/menos5.svg';
import mas1Icon from '../../images/mas1.svg';
import mas5Icon from '../../images/mas5.svg';
import masIcon from '../../images/mas.svg';
import flechaAbajo from '../../images/flecha-arriba.svg';
import EnVivoIcon from '../utilities/EnVivoIcon';
import { Programa } from '../../types/types';

import PlayerOptions from './PlayerOptions';
import SeekSlider from './SeekSlider';
import VolumeSlider from './VolumeSlider';

const StyledExpandedPlayer = styled.div<{ $isExpanded: boolean; $isLiveOn: boolean }>`
  @media (min-width: 576px) {
    position: fixed;
    bottom: ${(props: any) => (props.$isExpanded ? '40px' : 'unset')};
    left: ${(props: any) => (props.$isExpanded ? '0' : 'unset')};
    right: ${(props: any) => (props.$isExpanded ? '0' : 'unset')};
    background-color: ${(props: any) =>
      props.$isLiveOn ? 'var(--rojo-principal)' : 'var(--verde-principal)'};
  }
  .expanded-player {
    font-family: 'TradeGothic';
    opacity: 0;
    height: 292px;
    transition: opacity 0.2s ease-out;
    @media (max-width: 576px) {
      background-color: ${(props: any) =>
        props.$isLiveOn ? 'var(--rojo-principal)' : 'var(--verde-principal)'};
      height: 355px;
    }
    &.expanded {
      opacity: 1;
    }
    .close-btn-container {
      text-align: right;
      padding: 0 27px;
      background-color: ${(props: any) =>
        props.$isLiveOn ? 'var(--rojo-oscuro)' : 'var(--verde-oscuro)'};
      button {
        transform: rotate(180deg);
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .expanded-player-controls-and-header {
      @media (min-width: 576px) {
        max-width: 1100px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        margin-top: 32px;
      }
      .expanded-player-header {
        font-family: 'TradeGothic Gold18';
        color: white;
        text-transform: uppercase;
        border-bottom: ${(props: any) => props.$isLiveOn && '1px solid white'};
        padding: 16px;
        display: flex;
        flex-direction: column;
        /* Z-index is applied here because when PlayerOptions is displayed, the buttons needs to be above them.  */
        z-index: 10;
        @media (min-width: 576px) {
          border-bottom: none;
          border-right: 1px solid white;
          height: 208px;
          padding: 16px 16px 0;
        }

        .program-info-header {
          border-bottom: 1px solid white;
          padding-bottom: 16px;

          .live-icon-container {
            display: flex;
            align-items: center;

            span {
              margin-left: 8px;
            }
          }
          .program-title {
            color: white;
            display: block;
            font-family: 'TradeGothic Gold20';
            font-size: 28px;
            text-transform: uppercase;
            width: 216px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @media (min-width: 576px) {
              width: 40vw;
            }
          }
          .podcast-title {
            width: 216px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @media (min-width: 576px) {
              width: 40vw;
            }
          }
        }
        .program-info-horario-and-conducen {
          display: grid;
          grid-template-columns: 3fr 1fr;
          margin-top: 16px;
          align-self: normal;
          height: 100%;

          .conducen-horario-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            p {
              text-transform: uppercase;
              align-items: center;
              border-right: 1px solid white;
              white-space: nowrap;
              overflow: hidden;
              width: 248px;
              text-overflow: ellipsis;
              padding-right: 16px;

              @media (min-width: 576px) {
                width: 616px;
              }
            }
          }
          .options {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: flex-start;

            @media (min-width: 576px) {
              justify-content: flex-start;
              align-items: center;
            }

            button {
              background-color: transparent;
              border: none;
              color: white;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 8px;
              font-size: 13.6px;

              @media (min-width: 576px) {
                margin-left: 16px;
              }

              img {
                width: 25.6px;
                height: 25.6px;
                margin-bottom: 8px;
              }
            }
          }
        }
        p {
          text-transform: none;
        }
      }
      .player-controls-container {
        display: flex;
        margin: 16px;
        justify-content: center;
        position: relative;
        @media (min-width: 576px) {
          margin-bottom: ${(props: any) => !props.$isLiveOn && '16px'};
        }
        button {
          background-color: transparent;
          border: none;
          margin: 0 4.8px;
          img {
            width: 32px;
            height: 32px;
          }
          &.play-btn,
          &.pause-btn {
            img {
              width: 80px;
              height: 80px;
            }
          }
        }
        .volume {
          margin-left: 16px;
          @media (min-width: 576px) {
            position: absolute;
            height: 80px;
            left: -107.2px;
            top: 107.2px;
            z-index: 11;
          }
        }
      }
    }
  }
`;

interface ExpandedPlayerProps {
  audioStatus: string;
  program: Programa;
  podcastTitle?: string;
  podcastSlug?: string;
  isLiveOn: boolean;
  isExpanded: boolean;
  on5less: () => void;
  on1less: () => void;
  onPlay: () => void;
  onPause: () => void;
  on1more: () => void;
  on5more: () => void;
  handleClose: () => void;
  player: HTMLAudioElement;
}

const ExpandedPlayer = ({
  audioStatus,
  program,
  podcastTitle,
  isLiveOn,
  isExpanded,
  on5less,
  on1less,
  onPlay,
  onPause,
  on1more,
  on5more,
  handleClose,
  player,
}: ExpandedPlayerProps) => {
  const [isOptionsDisplayed, setIsOptionsDisplayed] = useState<boolean>(false);

  return (
    <StyledExpandedPlayer $isExpanded={isExpanded} $isLiveOn={isLiveOn}>
      <PlayerOptions
        handleClose={handleClose}
        isOptionsDisplayed={isOptionsDisplayed}
        isPodcast={!isLiveOn}
        setIsOptionsDisplayed={setIsOptionsDisplayed}
      />
      <div className={isExpanded ? 'expanded-player expanded' : 'expanded-player'}>
        <div className="close-btn-container">
          <button onClick={handleClose}>
            <img alt="Icono flecha cerrar reproductor" src={flechaAbajo} />
          </button>
        </div>
        <div className="expanded-player-controls-and-header">
          <div className="expanded-player-header">
            <div className="program-info-header">
              <div className="live-icon-container">
                {isLiveOn ? (
                  <EnVivoIcon
                    height="48px"
                    isPlaying={audioStatus === 'playing'}
                    left="0"
                    top="-2.4px"
                    width="48px"
                  />
                ) : (
                  <EnVivoIcon
                    isPodcast
                    height="24px"
                    isPlaying={audioStatus === 'playing'}
                    left="0"
                    top="-2.4px"
                    width="54px"
                  />
                )}
                <span>Estas escuchando:</span>
              </div>
              <p className="program-title">{program.title}</p>
              {podcastTitle && (
                <p className="podcast-title" title={podcastTitle}>
                  {podcastTitle}
                </p>
              )}
            </div>
            <div className="program-info-horario-and-conducen">
              <div className="conducen-horario-container">
                <p title={program.conducen}>{program.conducen}</p>
                <p title={program.horario}>{program.horario}</p>
              </div>
              <div className="options">
                <button
                  aria-label="Abrir opciones del reproductor"
                  onClick={() => setIsOptionsDisplayed(!isOptionsDisplayed)}
                >
                  <img alt="Icono de opciones" src={masIcon} />
                  Opciones
                </button>
              </div>
            </div>
          </div>
          {!isLiveOn && <SeekSlider isPlaying={audioStatus === 'playing'} player={player} />}

          <div className="player-controls-container">
            <button onClick={on5less}>
              <img alt="Icono de retrasar 5 segundos el reproductor" src={menos5Icon} />
            </button>
            <button onClick={on1less}>
              <img alt="Icono de retrasar 1 segundo el reproductor" src={menos1Icon} />
            </button>
            {audioStatus === 'playing' ? (
              <button className="pause-btn" onClick={onPause}>
                <img alt="Icono de pausar" src={pauseIcon} />
              </button>
            ) : (
              <button className="play-btn" onClick={onPlay}>
                <img alt="Icono de reproducir" src={playIcon} />
              </button>
            )}

            <button onClick={on1more}>
              <img alt="Icono de adelantar 1 segundo el reproductor" src={mas1Icon} />
            </button>
            <button onClick={on5more}>
              <img alt="Icono de adelantar 5 segundos el reproductor" src={mas5Icon} />
            </button>
            <div className="volume">
              <VolumeSlider player={player} />
            </div>
          </div>
        </div>
      </div>
    </StyledExpandedPlayer>
  );
};

export default ExpandedPlayer;
