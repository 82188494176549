import React from 'react';
import styled from 'styled-components';

import '../styles/global.scss';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import SearchBox from '../components/footer/SearchBox';
import { useAppSelector } from '../types/hooks';
import PageFooter from '../components/footer/PageFooter';

const StyledLayoutComponent = styled.div<{ $isMenuOpen: boolean }>`
  .main-content {
    @media (min-width: 576px) {
      max-width: 1100px;
      margin: ${(props: any) => (props.$isMenuOpen ? '0 auto' : '0 auto 4rem')};
    }
  }
`;

type LayoutProps = {
  children: React.ReactNode;
};

function Layout({ children }: LayoutProps) {
  const { isSearchBoxVisible, isMenuOpen } = useAppSelector(
    ({ app: { isSearchBoxVisible, isMenuOpen } }) => ({
      isSearchBoxVisible,
      isMenuOpen,
    }),
  );

  return (
    <div>
      <StyledLayoutComponent $isMenuOpen={isMenuOpen}>
        <Header />
        <main className="main-content">{children}</main>
        {isSearchBoxVisible && <SearchBox />}
        <PageFooter />
        <Footer />
      </StyledLayoutComponent>
    </div>
  );
}

export default Layout;
