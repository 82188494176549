import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import VivoIcon from '../../images/en-vivo.svg';
import PodcastIcon from '../../images/programas.svg';

const opacityKeyFrame = keyframes`
  0% {
      opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const StyledEnVivoIcon = styled.div<{
  width: string;
  height: string;
  top: string;
  left: string;
  isPlaying: boolean;
}>`
  width: ${(props: any) => props.$width};
  height: ${(props: any) => props.$height};
  top: ${(props: any) => props.$top};
  left: ${(props: any) => props.$left};
  position: relative;
  animation: ${(props: any) =>
    props.$isPlaying
      ? css`
          ${opacityKeyFrame} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
        `
      : ''};
  img {
    width: ${(props: any) => props.$width};
    height: ${(props: any) => props.$height};
  }
`;

type EnVivoIconProps = {
  isPlaying: boolean;
  isPodcast?: boolean;
  width: string;
  height: string;
  top: string;
  left: string;
};

const EnVivoIcon = ({
  isPlaying,
  isPodcast = false,
  width,
  height,
  top,
  left,
}: EnVivoIconProps) => (
  <StyledEnVivoIcon $height={height} $isPlaying={isPlaying} $left={left} $top={top} $width={width}>
    {isPodcast ? (
      <img alt="Icono de podcast" src={PodcastIcon} />
    ) : (
      <img alt="Icono de vivo" src={VivoIcon} />
    )}
  </StyledEnVivoIcon>
);

export default EnVivoIcon;
