import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { changePlayerInfo, changePodcastInfo, setIsSearchBoxVisible } from '../../slices/AppSlice';
import { useAppDispatch, useAppSelector } from '../../types/hooks';
import HomeIcon from '../../images/home.svg';
import HomeWhiteIcon from '../../images/home-blanco.svg';
import BuscarIcon from '../../images/lupa-negra.svg';
import BuscarWhiteIcon from '../../images/lupa-blanca.svg';
import RadioEnVivoIcono from '../../images/radio-en-vivo.svg';
import RadioEnVivoWhiteIcono from '../../images/radio-en-vivo-blanco.svg';
import BasquetIcono from '../../images/icono-basquet.svg';
import BasquetWhiteIcon from '../../images/icono-basquet-blanco.svg';
import { BASQUETBOL_URL, CARNAVAL_URL, MALDONADO_URL, VIVO_URL } from '../../utils/constants';
import { shouldSpecialTransmitionBeDisplayed } from '../../utils/utilities';

import Player from './Player';

const StyledFooter = styled.div<{ $isLive: boolean; $expandedPlayer: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props: any) =>
    props.$isLive ? 'var(--rojo-principal)' : 'var(--verde-principal)'};
  height: 4rem;
  box-shadow: 0rem -2.3rem 1rem -0.3rem #000000bd;
  @media (min-width: 576px) {
    box-shadow: 0px 15px 10px 15px #000000bd;
    height: 2.5rem;
  }
  .footer-desktop-container {
    display: flex;
    flex-direction: column;
    @media (min-width: 576px) {
      flex-direction: row;
      max-width: 1100px;
      margin: 0 auto;
    }
    .main-controls {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: white;
      font-family: 'TradeGothicN2';
      padding: 0.5rem 0;
      z-index: 1;
      position: relative;
      bottom: 21.7rem;
      transition: transform 0.2s ease-out;

      @media (min-width: 576px) {
        background-color: transparent;
        bottom: 0;
        width: 50%;
        transform: ${(props: any) => (props.$expandedPlayer ? 'translateX(-50%)' : 'unset')};
        justify-content: ${(props: any) => (props.$expandedPlayer ? 'center' : 'flex-end')};
        padding: 0;
        height: 2.5rem;
      }
      a {
        color: black;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.75rem;

        @media (min-width: 576px) {
          margin-right: 3rem;
        }
        img {
          width: 1.5rem;
          height: 1.5rem;
          @media (min-width: 576px) {
            width: 1.3rem;
            height: 1.3rem;
          }
          &.white {
            display: none;
          }
        }
        @media (min-width: 576px) {
          color: white;
          img {
            &.white {
              display: block;
            }
            &.black {
              display: none;
            }
          }
        }
      }
      button {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'TradeGothicN2';
        @media (min-width: 576px) {
          margin-right: 3rem;
        }
        img {
          width: 1.5rem;
          height: 1.5rem;
          @media (min-width: 576px) {
            width: 1.3rem;
            height: 1.3rem;
          }
          &.white {
            display: none;
          }
        }
        @media (min-width: 576px) {
          color: white;
          img {
            &.white {
              display: block;
            }
            &.black {
              display: none;
            }
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const dispatch = useAppDispatch();
  const [isLive, setIsLive] = useState<boolean>(true);
  const [expandedPlayer, setExpandedPlayer] = useState<boolean>(false);
  const [showBasketballIcon, setShowBasketballIcon] = useState<boolean>(false);
  const {
    playerInfo: { playerUrl, playerSlug, isFooterBtnClicked, timeStamp },
    podcastInfo: { podcastTitle, podcastSlug },
    basquetbolHours,
  } = useAppSelector(({ app }) => ({
    playerInfo: app.playerInfo,
    podcastInfo: app.podcastInfo,
    basquetbolHours: app.basquetbolHours,
  }));

  const dispatchPlayerChange = (basket?: boolean) => {
    dispatch(
      changePlayerInfo({
        playerUrl: basket ? BASQUETBOL_URL : VIVO_URL,
        playerSlug: basket ? 'basquetbol' : 'radio-en-vivo',
        isFooterBtnClicked: true,
      }),
    );
    dispatch(
      changePodcastInfo({
        podcastTitle: '',
        podcastSlug: '',
      }),
    );
  };

  useEffect(() => {
    if (basquetbolHours)
      setShowBasketballIcon(shouldSpecialTransmitionBeDisplayed(basquetbolHours));
  }, [basquetbolHours]);

  useEffect(() => {
    setIsLive([VIVO_URL, MALDONADO_URL, BASQUETBOL_URL, CARNAVAL_URL].includes(playerUrl));
  }, [playerUrl]);

  return (
    <>
      <StyledFooter $expandedPlayer={expandedPlayer} $isLive={isLive}>
        <div className="footer-desktop-container">
          <Player
            expandedPlayer={expandedPlayer}
            isFooterBtnClicked={isFooterBtnClicked}
            isLive={isLive}
            playerSlug={playerSlug}
            playerUrl={playerUrl}
            podcastSlug={podcastSlug}
            podcastTitle={podcastTitle}
            setExpandedPlayer={setExpandedPlayer}
            timeStamp={timeStamp}
          />
          <div className="main-controls">
            <Link to="/">
              <img alt="Icono de ir a la página principal" className="black" src={HomeIcon} />
              <img alt="Icono de ir a la página principal" className="white" src={HomeWhiteIcon} />
              Inicio
            </Link>
            <button onClick={() => dispatchPlayerChange()}>
              <img alt="Icono de radio en Vivo" className="black" src={RadioEnVivoIcono} />
              <img alt="Icono de radio en Vivo" className="white" src={RadioEnVivoWhiteIcono} />
              En vivo
            </button>
            {showBasketballIcon && (
              <button onClick={() => dispatchPlayerChange(true)}>
                <img alt="Icono de radio en Vivo" className="black" src={BasquetIcono} />
                <img alt="Icono de radio en Vivo" className="white" src={BasquetWhiteIcon} />
                Básquetbol
              </button>
            )}
            <button onClick={() => dispatch(setIsSearchBoxVisible(true))}>
              <img alt="Icono de buscar" className="black" src={BuscarIcon} />
              <img alt="Icono de buscar" className="white" src={BuscarWhiteIcon} />
              Buscar
            </button>
          </div>
        </div>
      </StyledFooter>
    </>
  );
};

export default Footer;
