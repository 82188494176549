import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { useAppDispatch, useAppSelector } from '../../types/hooks';
import { setIsMenuOpen } from '../../slices/AppSlice';
import MenuIcon from '../../images/menu-negro.svg';
import CloseIcon from '../../images/cerrar.svg';
import Logo from '../../images/logos/logo_nuevo.svg';

import DesktopNavBar from './DesktopNavBar';
import SocialNetworks from './SocialNetworks';
import Menu from './Menu';

const StyledHeaderComponent = styled.header<{ $isMenuOpen: string }>`
  padding: 8px 0 8px;
  position: ${(props: any) => (props.$isMenuOpen ? 'fixed' : 'unset')};
  background-color: white;
  z-index: 2;
  width: 100%;

  @media (min-width: 576px) {
    border-top: 4px solid var(--rojo-principal);
    padding: 8px 0 0;
    margin-bottom: 32px;
    box-shadow: 0px 5px 10px -3px #0000001a;
    position: sticky;
    top: ${(props: any) => (props.$isMenuOpen ? '0' : '-4.75rem')};
  }

  .desktop-container {
    display: flex;
    flex-direction: column;
    @media (min-width: 576px) {
      max-width: 1100px;
      margin: 0 auto;
    }

    .logo-and-menu-icon-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      @media (min-width: 576px) {
        margin-bottom: 8px;
      }

      button {
        margin-right: 16px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #e52126; // for contrast only
        font-family: 'NexaRegular';

        @media (min-width: 576px) {
          margin: 0;
        }

        #logo-icon-desktop {
          width: 56px;
          height: 56px;
          margin-left: 8px;
          object-fit: contain;
          object-position: left;

          @media (min-width: 576px) {
            margin-left: 0;
            margin-right: 8px;
          }
        }
      }

      .emisoras {
        display: none;
        border-left: 0.5px solid #414042;
        border-right: 0.5px solid #414042;
        padding-left: 16px;
        height: 24px;
        align-items: center;

        @media (min-width: 576px) {
          display: flex;
        }

        p {
          font-size: 0.85rem;
          color: #414042;
          font-family: 'NexaLight';
          margin-right: 16px;

          b {
            font-family: 'NexaBold';
          }
        }
      }

      .desktop-menu-social-network-container {
        display: flex;
        align-items: center;
        font-size: 1.125rem;

        .desktop-social-network-container {
          display: none;

          @media (min-width: 576px) {
            display: block;
            margin-right: 2rem;
            width: 16rem;
          }
        }

        .menu-icon {
          color: #414042;
          width: 5.2rem;

          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }

          @media (min-width: 576px) {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

const Header = () => {
  const isMenuOpen = useAppSelector(({ app }) => app.isMenuOpen);
  const dispatch = useAppDispatch();

  const onClickLog = () => {
    dispatch(setIsMenuOpen(false));
    navigate('/');
  };

  return (
    <StyledHeaderComponent $isMenuOpen={isMenuOpen}>
      <div className="desktop-container">
        <div className="logo-and-menu-icon-header">
          <button aria-label="Botón Volver a Inicio" onClick={onClickLog}>
            <img alt="Logo de la radio M24" id="logo-icon-desktop" src={Logo} />
            <p>Nos mueve la información</p>
          </button>
          <div className="emisoras">
            <p>
              <b>97.9 fm</b> MVD
            </p>
            <p>
              <b>102.5 fm</b> Maldonado
            </p>
          </div>
          <div className="desktop-menu-social-network-container">
            <div className="desktop-social-network-container">
              <SocialNetworks />
            </div>
            {isMenuOpen ? (
              <button className="menu-icon" onClick={() => dispatch(setIsMenuOpen(false))}>
                <img alt="Close Menu Icon" src={CloseIcon} />
                Cerrar
              </button>
            ) : (
              <button className="menu-icon" onClick={() => dispatch(setIsMenuOpen(!isMenuOpen))}>
                <img alt="Icono de menú" src={MenuIcon} />
                Menú
              </button>
            )}
          </div>
        </div>
        {isMenuOpen && <Menu />}
      </div>
      <DesktopNavBar />
    </StyledHeaderComponent>
  );
};

export default Header;
