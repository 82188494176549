import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  MailShareButton,
} from '../utilities/SocialMediaShareButtons';
import { useAppSelector } from '../../types/hooks';
import ShareIcon from '../../images/compartir-blanco.svg';
import AudifonosIcon from '../../images/podcast-blanco.svg';
import DescargarIcon from '../../images/descargar.svg';
import CerrarIcon from '../../images/cerrar-blanco.svg';
import CircularSpinner from '../utilities/CircularSpinner';
import { downloadAudio } from '../../utils/utilities';

const StyledPlayerOptionsContainer = styled.div<{ $isPodcast: boolean; $isPopUpOpened: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props: any) => (props.$isPopUpOpened ? 'flex' : 'none')};
  justify-content: center;
  height: ${(props: any) => (props.$isPodcast ? '7.4rem' : '7.9rem')};
  transition: bottom 0.4s ease-out;
  z-index: 10;
  color: white;
  @media (min-width: 576px) {
    height: 7.9rem;
    padding-top: 2rem;
  }
  @media (min-width: 1441px) {
    align-items: center;
    padding-top: 0;
  }
  .box-container {
    width: 100%;
    min-height: 7.5rem;
    background-color: ${(props: any) =>
      !props.$isPodcast ? 'var(--rojo-principal)' : 'var(--verde-principal)'};
    position: relative;
    @media (min-width: 576px) {
      min-height: 10rem;
      width: 26rem;
      margin: 0 auto;
      transform: translate(29rem, -4rem);
    }
    .close-button {
      position: absolute;
      right: 2rem;
      top: 1rem;
      @media (min-width: 576px) {
        right: 6rem;
        top: -1rem;
      }
      img {
        width: 1rem;
        height: 1rem;
      }
    }
    ul {
      padding: 0 2rem;
      margin-top: 0.5rem;
      li {
        display: flex;
        padding: 0.5rem 0;
        font-family: 'TradeGothicN2';
        align-items: center;
        font-size: 1rem;
        &:last-child {
          border-bottom: none;
        }
        button,
        .download-link {
          color: white;
          cursor: pointer;

          img {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 1rem;
          }
          &.with-text {
            font-family: 'TradeGothicN2';
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            &.disabled {
              color: #ffffff87;
              cursor: default;
            }
          }
          p {
            font-family: 'TradeGothicN2';
            font-size: 0.9rem;
          }
        }
        &.compartir-container {
          .share-buttons {
            display: flex;
            img {
              width: 1rem;
              height: 1rem;
              margin-left: 1.25rem;
            }
          }
        }
      }
    }
  }
`;

type PlayerOptionsProps = {
  isOptionsDisplayed: boolean;
  setIsOptionsDisplayed: (value: boolean) => void;
  isPodcast?: boolean;
  handleClose: () => void;
};

const PlayerOptions = ({
  isOptionsDisplayed,
  setIsOptionsDisplayed,
  isPodcast = false,
  handleClose,
}: PlayerOptionsProps) => {
  const {
    podcastInfo: { podcastTitle, podcastSlug },
    playerInfo: { playerSlug, playerUrl },
  } = useAppSelector(({ app }) => ({
    podcastInfo: app.podcastInfo,
    playerInfo: app.playerInfo,
  }));
  const { site } = useStaticQuery(query);
  const [fetching, setFetching] = useState<boolean>(false);

  const downloadFile = async (url: string, name: string) => {
    downloadAudio({ fetching, setFetching, url, name });
  };

  return (
    <StyledPlayerOptionsContainer $isPodcast={isPodcast} $isPopUpOpened={isOptionsDisplayed}>
      <div className="box-container" onClick={(event) => event.stopPropagation()}>
        <button
          aria-label="cerrar opciones"
          className="close-button"
          onClick={() => setIsOptionsDisplayed(false)}
        >
          <img alt="Icono de cerrar opciones" src={CerrarIcon} />
        </button>
        <ul>
          <li className="compartir-container">
            <button aria-label="Botón de compartir noticia">
              <img alt="Imagen de compartir" src={ShareIcon} />
            </button>
            <p>Compartir</p>
            <div className="share-buttons">
              <FacebookShareButton blanco noText />
              <TwitterShareButton
                blanco
                noText
                slug={podcastSlug ? podcastSlug : ''}
                title={podcastTitle ? podcastTitle : site.siteMetadata.defaultTitle}
              />
              <WhatsappShareButton
                blanco
                noText
                slug={podcastSlug ? podcastSlug : ''}
                title={podcastTitle ? podcastTitle : site.siteMetadata.defaultTitle}
              />
              <MailShareButton
                blanco
                noText
                slug={podcastSlug ? podcastSlug : ''}
                title={podcastTitle ? podcastTitle : site.siteMetadata.defaultTitle}
              />
            </div>
          </li>
          {podcastSlug && (
            <li>
              <button
                aria-label="Botón de ir a la sección del programa"
                className="with-text"
                onClick={() => {
                  setIsOptionsDisplayed(false);
                  handleClose();
                  navigate(`/${playerSlug}`);
                }}
              >
                <img alt="Icono de ir a la sección del programa" src={AudifonosIcon} />
                <p>Ir a la sección del programa</p>
              </button>{' '}
            </li>
          )}
          {podcastSlug && (
            <li>
              <a
                aria-label="Botón de descargar el podcast"
                className={`download-link with-text ${fetching ? 'disabled' : ''}`}
                href="#"
                rel="noreferrer"
                onClick={() => downloadFile(playerUrl, podcastTitle)}
              >
                <img alt="Icono de descargar podcast" src={DescargarIcon} />
                <p>Descargar</p>
              </a>
            </li>
          )}
        </ul>
        {fetching && <CircularSpinner height={1.5} margin="-1.7rem 9rem" width={1.5} />}
      </div>
    </StyledPlayerOptionsContainer>
  );
};

export default PlayerOptions;

const query = graphql`
  query METADATA_SITE {
    site {
      buildTime
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
        author
      }
    }
  }
`;
